import { useCallback } from 'react';

import { useUser } from 'context/userContext';

import { isLocalhost } from 'utils/urlUtils';

export const useFullStory = () => {
  const { userDetails, modules, moduleProjects } = useUser();

  const initiateFullStory = useCallback(() => {
    const { FS } = window;
    if (userDetails?.email && !isLocalhost()) {
      const currentModule = modules?.find(module => module.isCurrent);
      const currentProject = moduleProjects(currentModule.key)?.find(
        project => project.projectID === currentModule.currentProject,
      );
      if (FS) {
        FS('setIdentity', {
          uid: userDetails.userId,
          properties: {
            displayName: userDetails.name,
            email: userDetails.email,
            user_id: userDetails.userId,
            userClientName: userDetails.clientName,
            userClientId: userDetails.clientId,
            moduleName: currentModule?.name,
            currentProjectId: currentModule?.currentProject,
            projectClientName: currentProject?.projectClientName,
            projectDisplayName: currentProject?.projectDisplayName,
            company_id: userDetails.clientId,
            name: userDetails.clientName,
          },
        });
      }
    }
  }, [userDetails, modules, moduleProjects]);

  return { initiateFullStory };
};
