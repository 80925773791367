import { useParams } from 'react-router-dom';

import { useInsightsAi } from 'context/insightsAiContext';

import { camelToFlat } from 'utils/formatStrings';

import { useApi } from './useApi';

export const useCoachMe = () => {
  const { call } = useApi();
  const { projectId } = useParams();

  const {
    setServerLoading,
    updateChatHistory,
    insightAiFilters,
    currentEmbedProject,
    setServerError,
    defaultInsightAiFilters,
    setConversationId,
    conversationId,
    projectKey,
    projectRoleId,
  } = useInsightsAi();

  const getCoachMe = async () => {
    let response = [];
    setServerLoading(true);

    try {
      response = await call({
        method: 'POST',
        url: '/Common/CoachMe',
        data: {
          key: projectKey,
          RoleID: [projectRoleId],
          projectId,
          clientName: currentEmbedProject?.projectClientName,
          insightFilters: {
            startDate: defaultInsightAiFilters.startDate,
            endDate: defaultInsightAiFilters.endDate,
            ...insightAiFilters,
          },
          conversationId,
        },
      });
      const { coachMe } = response;

      updateChatHistory({
        messageId: response.messageId || Date.now(),
        response: Object.keys(coachMe)
          ?.map(key => ({
            title: camelToFlat(key),
            value: coachMe[key] ?? '',
          }))
          .filter(item => item.value.length > 0),
        chatType: 'coachMe',
        citationFeed: response?.citationFeed,
        error: null,
        conversationType: response?.conversationType,
        conversationId: response?.conversationId,
      });
      if (!conversationId) {
        setConversationId(response.conversationId);
      }
      setServerLoading(false);
      setServerError(false);
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
      setServerLoading(false);
      setServerError(true);
    }

    return response;
  };

  return { getCoachMe };
};
